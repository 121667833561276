import React, { useState } from "react"
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Typography,
    Button
} from "@mui/material"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

// const GroupCheckBoxesFilter = ({ title, checkBoxes, handleOnChange }) => (
//     <Accordion defaultExpanded sx={{ mb: '25px', borderRadius: '8px' }}>
//         <AccordionSummary
//             expandIcon={<ArrowDropDownIcon />}
//             aria-controls={`${title}-content`}
//             id={`${title}-header`}
//         >
//             <Typography sx={{ fontWeight: 600 }}>{title}</Typography>
//         </AccordionSummary>
//         <AccordionDetails sx={{ height: '240px', overflowX: 'scroll' }}>
//             <FormControl component="fieldset" variant="standard">
//                 <FormGroup>
//                     {checkBoxes.map(checkBox => (
//                         <FormControlLabel
//                             key={checkBox.id}
//                             control={
//                                 <Checkbox
//                                     checked={checkBox.isChecked}
//                                     onChange={() => handleOnChange(checkBox.id)}
//                                     name={checkBox.name}
//                                 />
//                             }
//                             label={checkBox.name}
//                         />
//                     ))}
//                 </FormGroup>
//             </FormControl>
//         </AccordionDetails>
//     </Accordion >
// )

const GroupCheckBoxesFilter = ({ title, checkBoxes, handleOnChange }) => {
    const [showAll, setShowAll] = useState(false);

    // Dividir los checkboxes en dos grupos
    const visibleCheckBoxes = showAll ? checkBoxes : checkBoxes.slice(0, 2);

    return (
        <Accordion defaultExpanded sx={{ mb: '25px', borderRadius: '8px' }}>
            <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls={`${title}-content`}
                id={`${title}-header`}
            >
                <Typography sx={{ fontWeight: 600 }}>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <FormControl component="fieldset" variant="standard">
                    <FormGroup>
                        {visibleCheckBoxes.map((checkBox) => (
                            <FormControlLabel
                                key={checkBox.id}
                                control={
                                    <Checkbox
                                        checked={checkBox.isChecked}
                                        onChange={() => handleOnChange(checkBox.id)}
                                        name={checkBox.name}
                                    />
                                }
                                label={checkBox.name}
                            />
                        ))}
                    </FormGroup>
                    {/* Botón para mostrar más o menos */}
                    {checkBoxes.length > 2 && (
                        <Button sx={{
                            color: 'rgba(0, 0, 0, 0.87)',
                            textAlign: 'left',
                            justifyContent: 'flex-start'
                        }} onClick={() => setShowAll(!showAll)}>
                            {showAll ? 'Ver menos' : 'Ver más'}
                        </Button>
                    )}
                </FormControl>
            </AccordionDetails>
        </Accordion >
    );
};

export default GroupCheckBoxesFilter
