export const getHrefFromGuide = (guide) => {
    const sanitizedCity = guide?.city_slug
        ?.replace(' ', '-')
        ?.normalize("NFD")
        .replace(/[\u0300-\u036f]/g, '')
        ?.toLowerCase()
    return `/guias/${sanitizedCity}/${guide.slug}`
}

export const getTourPath = (tour) => {
    if (tour.type === 'city') return getCityPath(tour);
    if (tour.type === undefined) return getHrefFromGuide(tour);
    const sanitizedCity = tour?.city_slug
        ?.replace(' ', '-')
        ?.normalize("NFD")
        .replace(/[\u0300-\u036f]/g, '')
        ?.toLowerCase()
    return `/tour/${sanitizedCity}/${tour.slug}`
}

export const getCityPath = (tour) => {
    if (tour.type === undefined) return getHrefFromGuide(tour);
    const sanitizedCity = tour?.city_slug
        ?.replace(' ', '-')
        ?.normalize("NFD")
        .replace(/[\u0300-\u036f]/g, '')
        ?.toLowerCase()
    return `/tours/${sanitizedCity}`
}