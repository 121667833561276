import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { selectTours } from "../../../app/features/cartSlice";
import { recomendedTours } from "../../../utils/data";
import Resumen from "./resumen";
import Recomendations from "./recomendations";
import { useScreenDetector } from "../../../hooks/useScreenDetector";
import { useNavigate } from "react-router-dom";
import useFetchSuggestTours from "../../../hooks/API/Tours/useFetchSuggestTours";

const Step1 = () => {
    const { isMobile } = useScreenDetector()
    const cartTours = useSelector(selectTours);
    const navigate = useNavigate();
    const tours = useSelector(state => state.cart.checkout.tours);
    const [fetchParams, setFetchParams] = useState(null);
    const [recommendations, setRecommendations] = useState(null);
    const suggestedTours = useFetchSuggestTours(fetchParams);
    const [city, setCity] = useState(null);

    const handleNext = () => {
        navigate('/checkout/datos-personales')
    }

    useEffect(() => {
        // Desplaza hacia el inicio al cargar la página
        window.scrollTo(0, 0);
    }, []);


    const getRandomProperty = (array, property) => {
        if (!array.length) return null; // Manejo de arreglos vacíos
        const randomIndex = Math.floor(Math.random() * array.length); // Índice aleatorio
        return array[randomIndex][property]; // Devuelve la propiedad
    };

    useEffect(() => {
        if (tours.length) {
            const city = getRandomProperty(tours, 'city');
            setFetchParams({ city, limit: 3 });
        }
    }, [tours]);


    useEffect(() => {
        if (suggestedTours) {
            const listSuggestions = suggestedTours.tourList.map(tour => {
                return {
                    imageUrl: tour.cover,
                    ivaIncluded: true,
                    languages: null,
                    time: null,
                    slug: tour.slug,
                    city: tour.city,
                    cancelable: false,
                    rate: tour.scores.score,
                    price: tour.price,
                    reviews: {
                        state: 'Excelente',
                        number: tour.reviewsTotal
                    },
                    description: tour.shortDescription,
                    title: tour.name,
                    date: tour.date,
                    type: 'Paquete premium(150€)',
                    assistants: '1 adulto, 3 ninos',
                    moreDetails: false,
                    edit: false,
                    remove: false
                }
            });
            setRecommendations(listSuggestions);
        }
    }, [suggestedTours]);

    return (
        <>
            <Resumen handleNext={handleNext} />
            {(!isMobile && recommendations) && <Recomendations city={city} recomendedTours={recommendations} />}
        </>
    )
}

export default Step1;


/*
{
        imageUrl: 'images/kalalau.png',
        ivaIncluded: true,
        languages: null,
        time: null,
        cancelable: false,
        rate: 8.2,
        price: 150.5,
        reviews: {
            state: 'Excelente',
            number: 9378
        },
        description: `Lorem ipsum dolor sit amet consectetur. Aliquet amet in proin nibh. 
            Habitasse metus quis at est platea sem venenatis pellentesque. Commodo velit nisl
            diam magnis massa volutpa.`,
        title: 'Sendero Kalalau y gastronomía local',
        date: '10 de junio de 2024',
        type: 'Paquete premium(150€)',
        assistants: '1 adulto, 3 ninos',
        moreDetails: false,
        edit: true,
        remove: true
    }
*/