import { createSelector, createSlice } from '@reduxjs/toolkit'

const initialState = {
  checkout: {
    tours: [],
    travelers: {
      mainPasseger: {},
      others: []
    }
  }
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    saveMainPassenger: (state, action) => {
      state.checkout.travelers.mainPasseger = action.payload;
    },
    add: (state, action) => {
      console.log('depredated');
      //state.tours.push(action.payload);
    },
    addTour: (state, action) => {
      const tours = JSON.parse(JSON.stringify(state.checkout.tours));
      const tour = tours.find(tour => tour.slug === action.payload.slug);
      if (tour) {
        const updatedTours = tours.map(t => {

          if (t.slug === action.payload.slug) {
            if (t.date !== action.payload.date) {
              t.date = action.payload.date;
            }

            return { ...t, ...action.payload };
          }
          return t;
        });
        state.checkout.tours = updatedTours;

      } else {
        state.checkout.tours.push(action.payload)
      }
    },
    remove: (state, action) => {
      state.checkout.tours = state.checkout.tours.filter(tour => tour.slug !== action.payload)
    },
    setAssitants: (state, action) => {
      console.log('depredated');
      //state.assistants = action.payload;
    },
    removeExtraActivities: (state, action) => {
      const stateCheckout = JSON.parse(JSON.stringify(state));
      const { checkout: { tours } } = stateCheckout;
      const { url } = action.payload;

      const newTours = tours.map((tour, index) => {
        if (tour.slug === url) {
          if (tour['extras'] === undefined) {
            tour['extras'] = {};
          }
          if (tour['extras']['activities'] === undefined) {
            tour['extras']['activities'] = [];
          }

          const newActivities = tour['extras']['activities'].filter(activity => activity.name !== action.payload.extras.name);
          console.log('newActivities', newActivities);
          tour['extras']['activities'] = newActivities;
          return tour;
        }
        return tour
      });

      state.checkout.tours = newTours;
    },
    setExtraActivities: (state, action) => {

      const stateCheckout = JSON.parse(JSON.stringify(state));
      const { checkout: { tours } } = stateCheckout;
      const { url } = action.payload;
      let extraWasAdded = false;


      const newTours = tours.map((tour, index) => {
        if (tour.slug === url) {
          if (tour['extras'] === undefined) {
            tour['extras'] = {};
          }
          if (tour['extras']['activities'] === undefined) {
            tour['extras']['activities'] = [];
          }

          //verificamos si ya existe la actividad agregada
          const activityExist = tour['extras']['activities'].find(activity => activity.name === action.payload.extras.name);
          //replace activity if exist, if not push the activity 
          if (activityExist) {
            const index = tour['extras']['activities'].indexOf(activityExist);
            tour['extras']['activities'][index] = action.payload.extras;
          } else {
            tour['extras']['activities'].push(action.payload.extras);
          }

          extraWasAdded = true;
          return tour;
        }
        return tour
      });


      if (!extraWasAdded) {
        const newTour = action.payload.tourData;
        newTour['extras'] = {};
        newTour['extras']['activities'] = [];
        newTour['extras']['activities'].push(action.payload.extras);
        newTours.push(newTour);
        console.log(newTours);
      }

      state.checkout.tours = newTours;

    },
    emptyCart: (state) => {
      state.checkout.tours = [];
      state.checkout.travelers.mainPasseger = {};
    }
  },
})

export const { add, remove, addTour, setAssitants, saveMainPassenger, emptyCart, setExtraActivities, removeExtraActivities } = cartSlice.actions

export const selectTours = (state) => state.cart.tours;

export const totalPrice = (state) => state.cart.checkout.tours.reduce((acc, item) => acc + item.total, 0);
const selectCartItems = (state) => state.cart.checkout.tours;

export const selectTotalPrice = createSelector(
  [selectCartItems],
  (items) =>
    items.reduce((total, item) => {
      let totalByActivity = 0;
      //if extras exist sum the total of the extras
      if (item.extras.activities !== undefined) {
        const paxes = item.booking.map(pax => {
          return {
            name: pax.title,
            value: pax.value
          }
        }) //>QUI SE SUMAN LOS PAXES

        item.extras.activities.forEach(activity => {
          activity.rates.forEach(rate => {
            const pax = paxes.find(pax => pax.name === rate.pax);
            if (pax) {
              totalByActivity += rate.price * pax.value;
            }
          });
        });
      }
      return Math.ceil((total + item.total + totalByActivity) * 1.21);

    }, 0)
);

export const selectProductTotal = createSelector(
  // Input selectors
  [selectCartItems, (state, name) => name],
  // Función de cálculo
  (items, name) => {
    const product = items.find((item) => item.name === name);
    let totalByActivity = 0;
    if (!product) return 0;

    if (product.extras['activities']) {
      const paxes = product.booking.map(pax => {
        return {
          name: pax.title,
          value: pax.value
        }
      }) //>QUI SE SUMAN LOS PAXES


      product.extras.activities.forEach(activity => {
        activity.rates.forEach(rate => {
          const pax = paxes.find(pax => pax.name === rate.pax);
          if (pax) {
            totalByActivity += rate.price * pax.value;
          }
        });
      });
    }

    //const customizationsCost = product.customizations.reduce((sum, c) => sum + c.price, 0);
    return Math.ceil((product.total + totalByActivity) * 1.21);
  }
);

export const selectAssitants = (state) => state.cart.assistants;

export default cartSlice.reducer
