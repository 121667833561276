import { Checkbox, FormControlLabel } from '@mui/material'
import BpRadio from '../BpRadio'

const AGREEMENT_LABEL_1 =
    'Algunas atracciones requieren documentación oficial para poder entrar. Encriptamos esta y la guardamos en nuestra base de datos para mejorar tu experiencia con nosotros. Puedes solicitar borrarla cuando quieras. Aprende más sobre cómo gestionamos tus datos y tus derechos en este enlace: '
const AGREEMENT_LABEL_2 = `Quiero subscribirme a la newsletter de Trekzy`

const Agreements = ({ handleChange, agreement1, agreement2, errors }) => {
    return (
        <>
            <FormControlLabel
                sx={{
                    '& .MuiTypography-body1': {
                        fontSize: { xs: '10px', md: '12px' },
                        color: errors.privacyPolice ? 'red' : 'inherit', // Cambia color si hay error
                    },
                    alignItems: 'flex-start'
                }}
                control={
                    <Checkbox
                        checked={agreement1}
                        onChange={(e) => handleChange(e.target.checked, 'agreement1')}
                        name={`checkbox-agreement1`}
                        inputProps={{ 'aria-label': 'special_documents' }}
                    />
                }
                label={
                    <span>
                        {AGREEMENT_LABEL_1}
                        <a
                            href="/politicas-de-privacidad"
                            target="_blank"
                            rel="noopener noreferrer">
                            Política de Privacidad
                        </a>
                    </span>
                }
            />
            <FormControlLabel
                sx={{
                    '& .MuiTypography-body1': {
                        fontSize: { xs: '10px', md: '12px' }
                    },
                    '& .MuiFormControlLabel-labelPlacementEnd': {
                        alignItems: 'flex-start'
                    }
                }}
                value="agreement2"
                label={AGREEMENT_LABEL_2}
                control={
                    <Checkbox
                        checked={agreement2}
                        onChange={(e) => handleChange(e.target.checked, 'agreement2')}
                        name={`checkbox-agreement2`}
                        inputProps={{ 'aria-label': 'special_documents' }}
                    />
                }
            />
        </>
    )
}

export default Agreements
