import axios from "axios";

export const fetchPredictions = async (baseUrl, value) => {
    const token = localStorage.getItem('token');

    try {
        const { data } = await axios.get(
            `${baseUrl}/index.php?r=api/destinationguides/search`,
            {
                headers: {
                    'x-authorization-token': token
                },
                params: {
                    name: value
                }
            }
        ) || {}

        return data
    } catch (err) {
        console.error('Error fetching guide predictions: ', err)
    }
}

export const fetchAutocompleteDestinations = async (baseUrl, value) => {
    const token = localStorage.getItem('token');

    try {
        const { data } = await axios.get(
            `${baseUrl}/index.php?r=api/destinations/searchdestinations`,
            {
                headers: {
                    'x-authorization-token': token
                },
                params: {
                    name: value
                }
            }
        ) || {}

        return data
    } catch (err) {
        console.error('Error fetching guide autocomplete: ', err)
    }
}

