import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    IconButton,
    Typography,
    Link,
    Box
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { styled } from '@mui/system'
import { useState } from 'react'
import { Remove } from '@mui/icons-material'

const CustomAccordionComponent = (props) => {
    const [expanded, setExpanded] = useState(false)
    const [expandedId, setExpandedId] = useState('')
    const isColorExpanded = !expanded ? 'primary.main' : 'secondary.main'

    const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
        paddingRight: theme.spacing(14),
        cursor: 'default !important'
    }))

    const CustomAccordion = styled(Accordion)(() => ({
        border: '2px solid #40C5E8',
        boxShadow: 'none',
        borderRadius: '12px !important',
        '&.MuiAccordion-root:first-of-type': {
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '0px'
        },
        '&.MuiAccordion-root::before': {
            height: '0px !important'
        },
        '&.Mui-expanded': {
            borderColor: '#223F7F',
            marginTop: '1rem !important',
            cursor: 'none'
        },
        '&.MuiAccordionSummary-content': {
            marginLeft: '24px !important'
        },
        '&.MuiAccordionSummary-contentGutters': {
            backgroundColor: '#f4f4 !important'
        }
    }))

    const PlusIconButton = styled(IconButton)(({ theme }) => ({
        position: 'absolute',
        right: -1,
        top: props.top || 36,
        display: 'block',
        transform: 'translateY(-50%)',
        backgroundColor: theme.palette.primary,
        '&.MuiIconButton-root:hover': {
            backgroundColor: '#0084a5'
        }
    }))

    const handleChange = (panel) => (isExpanded) => {
        setExpanded((prev) => !prev)
        setExpandedId(panel)
        return isExpanded
    }

    return (
        <CustomAccordion
            expanded={expandedId === 'panel1' && expanded}
            sx={{
                mt: '1rem',
                '@media (max-width: 430px)': { mt: '0.5rem' },
                borderRadius: { xs: '12px' }
            }}>
            <CustomAccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    paddingLeft: '12px',
                    paddingRight: '5px',
                    minWidth: 290,
                    alignItems: 'baseline !important'
                }}>
                <Typography
                    sx={{
                        color: isColorExpanded,
                        pt: expanded ? '.5rem' : '.75rem',
                        px: { xs: '.5rem', md: '1rem' },
                        minHeight: '3rem',
                        alignItems: 'center',
                        fontSize: '1rem'
                    }}>
                    {props.index}
                </Typography>
                <Typography
                    sx={{
                        color: isColorExpanded,
                        pt: expanded ? '.5rem' : { xs: '.3rem', md: '.75rem' },
                        px: { xs: '.5rem', md: '1rem' },
                        maxWidth: { xs: '260px', md: '1040px' },

                        fontSize: { xs: '0.75rem', md: '1rem' }
                    }}>
                    {props.title}
                </Typography>

                <PlusIconButton
                    onClick={handleChange('panel1')}
                    sx={{
                        borderTopRightRadius: { xs: '12px', md: '6px' },
                        borderBottomRightRadius: {
                            xs: expanded ? '0px' : '12px',
                            md: expanded ? '0px' : '6px'
                        },
                        borderBottomLeftRadius: '0px',
                        borderTopLeftRadius: '0px',
                        padding: {
                            xs: props.padding || '1.15rem',
                            md: '.78rem 1rem'
                        },
                        top: { sm: 36 },
                        backgroundColor: expanded
                            ? 'secondary.main'
                            : 'primary.main'
                    }}>
                    {expanded ? (
                        <Remove
                            color="white"
                            sx={{
                                marginTop: '7px',
                                width: { xs: '25px', md: '35px' },
                                height: { xs: '25px', md: '35px' }
                            }}
                        />
                    ) : (
                        <AddIcon
                            color="white"
                            sx={{
                                marginTop: '7px',
                                width: { xs: '25px', md: '35px' },
                                height: { xs: '25px', md: '35px' }
                            }}
                        />
                    )}
                </PlusIconButton>
            </CustomAccordionSummary>
            <AccordionDetails
                sx={{
                    paddingLeft: { xs: '1rem', sm: '4rem' },
                    mb: { xs: '0.5rem', sm: '1rem' },
                    mt: -1
                }}>
                <Typography
                    sx={{
                        color: isColorExpanded,
                        fontSize: { xs: '0.75rem', sm: '1rem' }
                    }}>
                    {props.content}
                    <Link href={props.link}>{props.textLink}</Link>
                </Typography>
            </AccordionDetails>
        </CustomAccordion>
    )
}

export default CustomAccordionComponent
