import React from 'react'
import { Box, Container, Divider, Typography } from '@mui/material'
import { Phone, EmailOutlined } from '@mui/icons-material'
import Form from '../Form'
import './styles.css'


const ContactUs = () => {
    return (
        <div className='bg-container'>
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' }
                }}>
                <Box
                    sx={{
                        width: { xs: '100%', md: '40%' }
                    }}>
                    <Typography
                        color="primary.contrastText"
                        sx={{ lineHeight: 1.2, mb: '2rem', textAlign: { xs: 'center', md: 'left' } }}
                        variant="h1">
                        Habla con nosotros
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1, height: '9rem' }}>
                        <Box sx={{ width: '10%' }}>
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    backgroundColor: '#ffffff',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    height: '2.5rem',
                                    p: '1rem',
                                    width: '2.5rem'
                                }}>
                                <Phone color="primary" />
                            </Box>
                        </Box>
                        <Box sx={{ width: '90%' }}>
                            <Typography
                                color="primary.contrastText"
                                sx={{
                                    fontSize: 18,
                                    fontFamily: 'Poppins',
                                    fontWeight: 500,
                                    lineHeight: 1.2,
                                    mb: '.6rem'
                                }}>
                                Teléfono
                            </Typography>
                            <Typography
                                color="primary.contrastText"
                                sx={{
                                    fontSize: 14,
                                    lineHeight: 1.5,
                                    mb: '.6rem'
                                }}>
                                ¿Dudas con tu reserva? Llámanos y habla con un
                                miembro del equipo.
                            </Typography>
                            <Typography
                                color="primary"
                                sx={{
                                    fontSize: 16,
                                    fontWeight: 600,
                                    mb: '6rem'
                                }}>
                                +34 91 7578557
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1, height: '8rem' }}>
                        <Box sx={{ width: '10%' }}>
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    backgroundColor: '#ffffff',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    height: '2.5rem',
                                    p: '1rem',
                                    width: '2.5rem'
                                }}>
                                <EmailOutlined color="primary" />
                            </Box>
                        </Box>
                        <Box sx={{ width: '90%' }}>
                            <Typography
                                color="primary.contrastText"
                                sx={{
                                    fontSize: 18,
                                    fontFamily: 'Poppins',
                                    fontWeight: 500,
                                    lineHeight: 1.2,
                                    mb: '.6rem'
                                }}>
                                Email Reservas
                            </Typography>
                            <Typography
                                color="primary.contrastText"
                                sx={{
                                    fontSize: 14,
                                    lineHeight: 1.5,
                                    mb: '.6rem'
                                }}>
                                Escríbenos y un miembro del equipo se pondrá en
                                contacto contigo lo antes posible.
                            </Typography>
                            <Typography
                                color="primary"
                                sx={{
                                    fontSize: 16,
                                    fontWeight: 600,
                                    mb: '6rem'
                                }}>
                                reservas@trekzy.com
                            </Typography>
                        </Box>
                    </Box>
                    <Divider color="white" height={1} sx={{ mb: 3 }} />
                    <Box sx={{ display: 'flex', gap: 1, height: '9rem', mb: { xs: '2rem', md: 0 } }}>
                        <Box sx={{ width: '10%' }}>
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    backgroundColor: '#40C5E8',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    height: '2.5rem',
                                    p: '1rem',
                                    width: '2.5rem'
                                }}>
                                <EmailOutlined color="white" />
                            </Box>
                        </Box>
                        <Box sx={{ width: '90%' }}>
                            <Typography
                                color="secondary.contrastText"
                                sx={{
                                    fontSize: 18,
                                    fontFamily: 'Poppins',
                                    fontWeight: 500,
                                    lineHeight: 1.2,
                                    mb: '.6rem'
                                }}>
                                Email Comercial
                            </Typography>
                            <Typography
                                color="primary.contrastText"
                                sx={{
                                    fontSize: 14,
                                    lineHeight: 1.5,
                                    mb: '.6rem'
                                }}>
                                ¿Quieres tener tu tour en nuestra web? ¿Quieres tener acceso al nuestro motor de reservas innovador?
                                Escríbenos a este email y nos pondremos en contacto contigo lo antes posible.
                            </Typography>
                            <Typography
                                color="primary"
                                sx={{
                                    fontSize: 16,
                                    fontWeight: 600,
                                    mb: '6rem'
                                }}>
                                partners@trekzy.com
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: { xs: '100%', md: '60%' }
                    }}>
                    <Form />
                </Box>
            </Container>
        </div>
    )
}

export default ContactUs
