import React from 'react'
import axios from 'axios'

const useFetchTourDetails = (name) => {
    const [tourDetails, setTourDetails] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(false);
    const [error, setError] = React.useState(null);

    const fetchTourDetails = React.useCallback(async () => {
        setIsLoading(true)
        const { REACT_APP_BASE_URL } = process.env
        const token = localStorage.getItem('token');

        try {
            const { data } = await axios.get(
                `${REACT_APP_BASE_URL}/index.php?r=api/tours/tourdetails`,
                {
                    headers: {
                        'x-authorization-token': token
                    },
                    params: {
                        name,
                    }
                }
            )


            setIsLoading(false)

            if (data?.tour) {
                setTourDetails(data.tour)
            }
        } catch (err) {
            console.error('Error fetching tour details ', err)
            setError(true)
            setIsLoading(false)
        }
    }, [name])

    React.useEffect(() => {
        if (name) {  // Solo hacer fetch si `name` tiene un valor
            fetchTourDetails();
        }
    }, [fetchTourDetails]);

    return {
        tourDetails,
        isLoading,
        setIsLoading,
        error
    }
}

export default useFetchTourDetails
