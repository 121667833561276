import React from 'react'
import {
    Container,
    Typography
} from '@mui/material'
import { useResolvedPath } from 'react-router-dom'
import { Helmet } from "react-helmet";
// components
import Breadcrumbs from '../../components/Breadcrumbs'
import BlogArticleButtons from '../../components/BlogArticleButtons'
import BlurImageWrapper from '../../components/BlurImageWrapper'
import DestinationGallery from '../../components/DestinationGallery'
import MainLayout from '../../components/MainLayout'
import RichText from '../../components/RichText'
// hooks
import useFetchGuideByURL from '../../hooks/API/Guides/useFetchGuideByUrl'
import useFetchTopGuides from '../../hooks/API/Guides/useFetchTopGuides'
// icons
import { ReactComponent as InfoIcon } from '../../assets/icons/info-white.svg'
import { ReactComponent as CameraIcon } from '../../assets/icons/camera.svg'
import { ReactComponent as BagpackIcon } from '../../assets/icons/bagpack.svg'
import { ReactComponent as TicketIcon } from '../../assets/icons/ticket.svg'
import { ReactComponent as CampTentIcon } from '../../assets/icons/camp-tent.svg'
import { ReactComponent as PlateIcon } from '../../assets/icons/plate.svg'
import GuiasBlogSkeleton from './GuiasBlogSkeleton'
import './styles.css'

const apiSections = [
    'informacion-principal-de',
    'que-ver-en',
    'excursiones-de',
    'que-hacer-en',
    'donde-dormir-en',
    'gastronomia-de',
]

const GuiasBlog = ({
    preview = false,
}) => {
    let paths = [];
    let page, city, slug;
    paths = window.location.pathname.split('/').filter(Boolean)
    if (window.location.pathname.indexOf('preview') === -1) {
        page = paths[0]
        city = paths[1]
        slug = paths[2]
    } else {
        page = paths[1]
        city = paths[2]
        slug = paths[3]
    }
    const [sections, setSections] = React.useState([])
    const [metaDescription, setMetaDescription] = React.useState([])

    const [heroTitle, setHeroTitle] = React.useState('');
    const { topGuides } = useFetchTopGuides()
    const [pageSection] = apiSections.filter(section => slug.includes(section))

    const { guide: pageData, isLoading: isPageLoading } = useFetchGuideByURL(
        city,
        pageSection,
        preview
    )

    const breadcrumbs = [
        {
            name: 'Guías de viaje',
            path: `/${page}`
        },
        {
            name: pageData?.city,
            path: `/${page}/${city}`
        },
    ]

    React.useEffect(() => {
        const sectionsData = pageData?.sections || []
        if (sectionsData.length) {
            const placeName = pageData?.city
            const defaultSections = [
                {
                    title: 'Información principal',
                    name: apiSections[0],
                    Icon: InfoIcon
                },
                {
                    title: `Qué ver en ${placeName}`,
                    name: apiSections[1],
                    Icon: CameraIcon
                },
                {
                    title: `Excursiones en ${placeName}`,
                    name: apiSections[2],
                    Icon: BagpackIcon
                },
                {
                    title: `Qué hacer en ${placeName}`,
                    name: apiSections[3],
                    Icon: TicketIcon
                },
                {
                    title: `Dónde dormir en ${placeName}`,
                    name: apiSections[4],
                    Icon: CampTentIcon
                },
                {
                    title: `Gastronomía en ${placeName}`,
                    name: apiSections[5],
                    Icon: PlateIcon
                }
            ]

            setHeroTitle(`${placeName} - ${sectionsData[0]?.section_name}`);
            // document.title = sectionsData[0]?.title;
            // document.querySelector('meta[name="description"]').setAttribute('content', sectionsData[0]?.description.slice(0, 155))

            setMetaDescription(sectionsData[0]?.description.slice(0, 155));

            const btnSections = defaultSections.map(section => {
                const path = `/guias/${city}/${section.name}`
                return ({
                    ...section,
                    path,
                })
            })
            setSections(btnSections)
        }
    }, [city, pageData, metaDescription])


    return (
        <MainLayout>
            <Helmet>
                <title>{pageData?.title || "Guía de Viaje"}</title>
                <meta
                    name="description"
                    content={metaDescription}
                    data-react-helmet="true"
                />
            </Helmet>
            {
                isPageLoading ? (
                    <GuiasBlogSkeleton />
                ) : (
                    <>
                        <BlurImageWrapper imageUrl={pageData?.imagen} >
                            <Container sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                py: '4rem'
                            }}>
                                <Typography
                                    color='primary'
                                    sx={{
                                        fontWeight: 500,
                                        lineHeight: 0,
                                    }}
                                    variant='textBody'
                                    component="h2"
                                >
                                    Guía de viaje
                                </Typography>
                                <Typography
                                    variant='h3'
                                    component="h1"
                                    color='secondary.contrastText'
                                    sx={{
                                        fontWeight: 'bold',
                                        mb: '1rem',
                                        textAlign: 'center',
                                    }}
                                >
                                    {heroTitle}
                                </Typography>
                                <Typography
                                    color='secondary.contrastText'
                                    variant='subtitle1'
                                    component='h2'
                                >
                                    {pageData?.title}
                                </Typography>
                            </Container>
                        </BlurImageWrapper>
                        <Container>
                            <Breadcrumbs breadcrumbs={breadcrumbs} />
                            <BlogArticleButtons
                                placeName={pageData?.city}
                                activeSection={slug || 'main-information'}
                                sections={sections}
                            />
                            <div className='guides-content-container'>
                                <RichText text={pageData?.sections?.[0]?.htmlcontent || ''} />
                            </div>
                        </Container>
                        <DestinationGallery title='Otros han buscado' destinations={topGuides || []} textPosition='center' />
                    </>
                )
            }
        </MainLayout>
    )
}

export default GuiasBlog
