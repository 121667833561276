import React, { useContext } from "react";

import {
    Box,
    Button,
    Card,
    CardContent,
    Divider,
    Grid,
    Link,
    Typography
} from "@mui/material";
import ProductCard2 from "../../../components/ProductCard2";
import { useScreenDetector } from "../../../hooks/useScreenDetector";
import SimpleProductCard from "./simpleProductCard";
import { ReactComponent as XSquare } from '../../../assets/icons/x-square.svg'
import { ReactComponent as Lock } from '../../../assets/icons/lock.svg'
import { ReactComponent as Clock } from '../../../assets/icons/clock.svg'
import CardResumen from "../cardResumen";
import ResumenAccordion from "../resumenAccordion";
import CuponLink from "../CuponLink";
import CheckoutContext from "../../../components/CheckoutContext/checkoutContext";

const Resumen = ({ handleNext }) => {
    const { isMobile } = useScreenDetector()
    const context = useContext(CheckoutContext);
    return (
        <Grid container
            spacing={2}
            sx={{
                mb: '40px',
            }}>
            <Grid item xs={12} md={8}>
                {!isMobile && context.cartTours.map(item => (
                    <ProductCard2 key={item.name} data={item} isCart={true} />
                ))}
                {isMobile && context.cartTours.map(item => (
                    <SimpleProductCard key={item.id} data={item} />
                ))}
                <Box sx={{ display: { xs: 'block', md: 'none' }, }}>
                    <Divider
                        aria-hidden="true"
                        sx={{
                            color: '#666B6E',
                            height: 2,
                            width: '100%'
                        }}
                    />
                    <Box
                        sx={{
                            width: '100%',
                            height: 'auto',
                            backgroundColor: '#F0F0F1',
                            borderRadius: '8px',
                            display: 'flex',
                            justifyContent: 'start',
                            p: '10px',
                            my: '20px'
                        }}
                    >
                        <Box>
                            <XSquare height={25} width={25} />
                        </Box>
                        <Box sx={{ pl: '10px' }}>
                            <Typography
                                color="secondary"
                                variant="h5"
                                component="h5"
                                sx={{
                                    fontSize: '16px'
                                }}
                            >
                                Cancelación gratuita
                            </Typography>
                            <Typography
                                variant="body2"
                                component="p"
                                sx={{
                                    color: '#666B6E',
                                    fontSize: '14px'
                                }}
                            >
                                Lorem ipsum dolor sit amet consectetur. Odio proin ullamcorper bibendum turpis ut sem.
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            height: 'auto',
                            backgroundColor: '#F0F0F1',
                            borderRadius: '8px',
                            display: 'flex',
                            justifyContent: 'start',
                            p: '10px',
                            my: '20px'
                        }}
                    >
                        <Box>
                            <Lock height={25} width={25} />
                        </Box>
                        <Box sx={{ pl: '10px' }}>
                            <Typography
                                color="secondary"
                                variant="h5"
                                component="h5"
                                sx={{
                                    fontSize: '16px'
                                }}
                            >
                                Pago seguro
                            </Typography>
                            <Typography
                                variant="body2"
                                component="p"
                                sx={{
                                    color: '#666B6E',
                                    fontSize: '14px'
                                }}
                            >
                                Lorem ipsum dolor sit amet consectetur. Odio proin ullamcorper bibendum turpis ut sem.
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            height: 'auto',
                            backgroundColor: '#F0F0F1',
                            borderRadius: '8px',
                            display: 'flex',
                            justifyContent: 'start',
                            p: '10px',
                            my: '20px'
                        }}
                    >
                        <Box>
                            <Clock height={25} width={25} />
                        </Box>
                        <Box sx={{ pl: '10px' }}>
                            <Typography
                                color="secondary"
                                variant="h5"
                                component="h5"
                                sx={{
                                    fontSize: '16px'
                                }}
                            >
                                ¿Falta alguien? Avísanos en el último momento
                            </Typography>
                            <Typography
                                variant="body2"
                                component="p"
                                sx={{
                                    color: '#666B6E',
                                    fontSize: '14px'
                                }}
                            >
                                Lorem ipsum dolor sit amet consectetur. Odio proin ullamcorper bibendum turpis ut sem.
                            </Typography>
                        </Box>
                    </Box>
                </Box>

            </Grid>
            <Grid
                item
                xs={12}
                md={4}
                sx={{
                    display: { xs: 'none', md: 'block' },
                    pl: '16px!important'
                }}
            >
                <Card sx={{
                    my: '15px',
                    borderRadius: '4px',
                    position: 'relative',
                    bottom: 0,
                    width: '100%',
                    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 6px 6px rgba(0,0,0,0.08)'
                }}>
                    <CardContent>
                        <ResumenAccordion />
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mt: '15px'
                        }}>
                            <Button
                                variant="contained"
                                onClick={handleNext}
                                sx={{
                                    width: '100%'
                                }}
                            >Continuar</Button>
                        </Box>
                    </CardContent>
                </Card>
                {/*<CuponLink />*/}
            </Grid>
            <Grid
                item
                xs={12}
                md={4}
                sx={{
                    pl: { xs: '0!important' },
                    display: { xs: 'block', md: 'none' },
                    width: '100%'
                }}
            >
                <CardResumen handleNext={handleNext} />
            </Grid>
        </Grid>
    )
}

export default Resumen;