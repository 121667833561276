import React from 'react'
import { Helmet } from "react-helmet";
import Hero from '../../components/Hero'
import GuideCards from '../../components/GuideCards'
import DestinationGallery from '../../components/DestinationGallery'
import GuidesTabs from '../../components/GuidesTabs'
import MainLayout from '../../components/MainLayout'
import useFetchTopGuides from '../../hooks/API/Guides/useFetchTopGuides'
import useFetchGuidesByRegion from '../../hooks/API/Guides/useFetchGuidesByRegion'
import heroImage from '../../assets/images/trekzy-blue-background.png'

const GuiasDeViaje = () => {
    const { topGuides } = useFetchTopGuides()
    const { guides } = useFetchGuidesByRegion()
    return (
        <MainLayout>
            <Helmet>
                <title>Guías de viaje</title>
                <meta
                    name="description"
                    content="Hemos preparado las guías de viaje más completas. Visita las ciudades que quieras sin miedo a perderte algo."
                    data-react-helmet="true"
                />
            </Helmet>
            <Hero
                bgImage={heroImage}
                placeHolderText="Busca tu guía de viaje"
                title="Guías de viaje"
                text="Hemos preparado las guías de viaje más completas. Visita las ciudades que quieras sin miedo a perderte algo."
                searchSource="guides"
            />
            <GuideCards />
            <DestinationGallery title="Top guías" destinations={topGuides || []} />
            <GuidesTabs tabsData={guides} />
        </MainLayout>
    )
}

export default GuiasDeViaje
