import React from "react";

import {
    Box,
    Typography
} from "@mui/material";
import SimpleProductCard from "../Step1/simpleProductCard";
import ResumenAccordion from "../resumenAccordion";
import CuponLink from "../CuponLink";

const Resumen = ({ cartTours }) => {
    return (
        <>
            <Box sx={{
                bgcolor: '#FFFFFF',
                p: '15px',
                mb: '15px',
                borderRadius: '8px',
                width: '100%',
            }}>
                <Typography
                    variant="h5"
                    color="secondary"
                    sx={{
                        fontSize: '18px',
                        mb: '10px'
                    }}
                >
                    Resumen
                </Typography>
                {cartTours.map(item => (
                    <SimpleProductCard key={item.name} data={item} />
                ))}
                <ResumenAccordion />
            </Box>
            {/*<CuponLink />*/}
        </>
    )
}

export default Resumen;