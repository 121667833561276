import React, { useEffect, useState } from "react";

import { selectTours, emptyCart } from "../../app/features/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import MainLayout from "../../components/MainLayout";
import { Box, Button, Container, Typography } from "@mui/material";
import ProductCard2 from "../../components/ProductCard2";
import './styles.css'
import bookingUrl from '../../assets/images/booking.png'
import { ReactComponent as Share } from "../../assets/icons/share.svg"
import { useScreenDetector } from "../../hooks/useScreenDetector";
import BookingProductCard from "./BookingProductCard";
import axios from 'axios';
import { useParams } from "react-router-dom";

const Booking = () => {
    var cartTours = useSelector(selectTours);
    const dispatch = useDispatch();
    const { isMobile } = useScreenDetector()

    const { confirmation } = useParams();

    const { REACT_APP_BASE_URL, REACT_APP_CDN_URL } = process.env
    const token = localStorage.getItem('token');

    const [dataBooking, setData] = useState([]);

    const handleShareClick = () => {
        const urlVoucher = `${REACT_APP_CDN_URL}/voucher_${confirmation}.pdf`; // URL del voucher

        // Mensaje a enviar
        const mensaje = `Hola 
Estamos muy contentos de que quieras compartir una parte de tu viaje con nosotros
Aquí tienes los detalles de tu reserva 

${urlVoucher}

Desde Trekzy – Tours Privados, esperamos que tu viaje sea espectacular!`;

        // Codificar el mensaje para la URL
        const mensajeCodificado = encodeURIComponent(mensaje);

        // Número de teléfono (incluye el código de país, sin "+")

        // Generar el enlace
        const linkWhatsapp = `https://wa.me/?text=${mensajeCodificado}`;

        // Abrir el enlace en una nueva pestaña
        window.open(linkWhatsapp, '_blank');
    };

    useEffect(() => {
        axios.get(`${REACT_APP_BASE_URL}/index.php?r=api/booking/get&confirmation=${confirmation}`,
            {
                headers: {
                    'x-authorization-token': token
                }
            })
            .then(response => {
                setData(response.data);
                dispatch(emptyCart());
            })
            .catch(error => {
                console.error('There was an error fetching the data!', error);
            });
    }, []);

    return (
        <MainLayout>
            <Box id="background-container">
                <Container
                    sx={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <Box
                        sx={{
                            my: '30px',
                            p: { xs: '15px 0px', md: '15px 40px' },
                            borderRadius: '8px',
                            width: '100%',
                            backgroundColor: '#FFFFFF'
                        }}
                    >
                        <Box sx={{
                            display: 'block',
                            textAlign: 'center',
                            mb: '40px'
                        }}>
                            <img
                                alt="toursprivados.com"
                                src={bookingUrl}
                                style={{
                                    width: '124px',
                                    height: '124px'
                                }}
                            />
                            <Typography
                                variant="h5"
                                color="secondary"
                                sx={{
                                    fontSize: '16px',
                                    mb: '10px'
                                }}
                            >
                                ¡Tu reserva está hecha!
                            </Typography>
                            <Typography
                                variant="body2"
                                color="p"
                                sx={{
                                    fontSize: '12px',
                                    mb: '10px'
                                }}
                            >
                                {/* Lorem ipsum dolor sit amet consectetur. Felis sit tempus email@email.com Dui tortor orci fusce facilisi. Fames lorem at pulvinar pulvinar et mi est sed */}
                            </Typography>
                            <Box sx={{
                                display: { xs: 'grid', md: 'block' }
                            }}>
                                <Button
                                    target="_blank"
                                    href={`${REACT_APP_CDN_URL}/voucher_${confirmation}.pdf`}
                                    variant="outlined"
                                    sx={{
                                        color: 'primary.main'
                                    }}
                                >Descargar PDF</Button>
                                <Button
                                    onClick={handleShareClick}
                                    color="secondary"
                                    variant="contained"
                                    sx={{
                                        ml: { xs: '0px', md: '15px' },
                                        mt: { xs: '15px', md: 0 }
                                    }}
                                >
                                    Compartir&nbsp;
                                    <Share />
                                </Button>
                            </Box>
                        </Box>
                        <Typography
                            variant="h5"
                            color="secondary"
                            sx={{
                                fontSize: '16px',
                                mb: '10px'
                            }}
                        >
                            Resumen de compra
                        </Typography>
                        {dataBooking.map(item => (<ProductCard2 key={item.name} data={item} showPrice={false} isCart={true} />))}
                        <Box sx={{
                            display: { xs: 'grid', md: 'block' },
                            textAlign: 'center',
                            mt: '20px',
                            mb: '10px'
                        }}>
                            <Button
                                href="/"
                                color="primary"
                                variant="contained"
                            >Seguir navegando</Button>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </MainLayout>
    )
}

export default Booking;