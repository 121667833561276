import React from 'react'
import { Box, Container, Typography } from '@mui/material'
import SearchInput from '../SearchInput'
import homeBgImage from '../../assets/images/hero.png'
import BlurImageWrapper, { opacities } from '../BlurImageWrapper'
import './styles.css'

const SimpleWrapper = ({ children, bgColor }) => (
    <Box
        sx={{
            backgroundColor: bgColor,
            width: '100%'
        }}>
        {children}
    </Box>
)

const Hero = ({
    title,
    titleHeader = '',
    text,
    bgColor = '',
    bgImage = '',
    footerTitle = '',
    footerText = '',
    placeHolderText = '',
    renderVector = false,
    renderInputSearch = true,
    searchSource = '',
    subText = '',
    opacity = opacities.none,
}) => {
    const BackgroundWrapper =
        !bgImage && bgColor ? SimpleWrapper : BlurImageWrapper
    const wrapperProps = {
        bgColor,
        imageUrl: bgImage || '',
        renderVector,
        opacity,
    }
    return (
        <>
            <BackgroundWrapper {...wrapperProps}>
                <Container
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        py: '4rem'
                    }}>
                    {
                        titleHeader ? (
                            <Typography
                                color='primary'
                                sx={{
                                    fontSize: 22,
                                    fontFamily: 'Poppins',
                                    fontWeight: 'bold',
                                    lineHeight: 1.2,
                                }}
                            >
                                {titleHeader}
                            </Typography>
                        ) : null
                    }
                    <Typography
                        className='title'
                        variant='h2'
                        color='secondary.contrastText'
                        sx={{
                            fontWeight: 'bold',
                            mb: '1rem',
                            fontSize: '72px !important',
                        }}>
                        <span>{title}</span>
                    </Typography>
                    <Typography
                        variant='body1'
                        color='secondary.contrastText'
                        sx={{
                            mb: subText ? 0 : '2rem',
                            px: '15%',
                            textAlign: 'center'
                        }}>
                        {text && text.split('\n').map((line, index) => (
                            <span key={index}>
                                {line}
                                <br />
                            </span>
                        ))}
                    </Typography>
                    {subText ? (
                        <Typography
                            variant='body'
                            color='secondary.contrastText'
                            sx={{ mb: { xs: '4rem', md: '2rem' } }}>
                            {subText}
                        </Typography>
                    ) : null}
                    {
                        renderInputSearch ? (
                            <SearchInput placeHolder={placeHolderText} source={searchSource} />
                        ) : null
                    }
                </Container>
            </BackgroundWrapper >
            {
                footerTitle ? (
                    <div className='hero-bottom' >
                        <Typography
                            color='secondary.contrastText'
                            variant='h1'
                            sx={{ mb: '1rem' }}>
                            {footerTitle}
                        </Typography>
                        <Typography
                            variant='body1'
                            color='secondary.contrastText'
                            sx={{ textAlign: 'center', maxWidth: 1024 }}>
                            {footerText}
                        </Typography>
                    </div >
                ) : null}
        </>
    )
}

export default Hero
