import React, { useCallback, useEffect, useState } from 'react'

import { Alert, Box, Button, Grid, Link, Typography } from '@mui/material'
import { ReactComponent as User } from '../../../assets/icons/user.svg'
import AccordionAssitant from './accordionAssitant'
import { selectTours, selectAssitants, setAssitants, saveMainPassenger } from '../../../app/features/cartSlice'
import { useDispatch, useSelector } from 'react-redux'
import Agreements from './agreements'
import FormTitular from './formTitular'
import Resumen from './resumen'
import CardResumen from '../cardResumen'
import { useNavigate } from 'react-router-dom'

const Step2 = () => {
    const cartTours = useSelector(selectTours)
    const assistants = useSelector(selectAssitants)
    const tours = useSelector((state) => state.cart.checkout.tours)
    const dispatch = useDispatch()
    const [agreement1, setAgreement1] = useState(false)
    const [agreement2, setAgreement2] = useState(false)
    const navigation = useNavigate();


    const handleAgreements = (target, agrr) => {

        if (agrr === 'agreement1') {
            // Usa el estado previo
            setAgreement1((prev) => !prev);
        } else if (agrr === 'agreement2') {
            setAgreement2((prev) => !prev); // Si manejas también el segundo acuerdo
        }
    };

    const [formData, setFormData] = useState({
        name: '',
        lastName: '',
        email: '',
        confirmEmail: '',
        passport: '',
        phone: ''
    })

    const [errors, setErrors] = useState({
        name: false,
        lastName: false,
        email: false,
        confirmEmail: false,
        passport: false,
        phone: false,
        privacyPolice: false
    })

    const [isFormValid, setIsFormValid] = useState(false)

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData({
            ...formData,
            [name]: value
        })
    }

    const validateForm = () => {
        const newErrors = {
            name: false,
            lastName: false,
            email: false,
            confirmEmail: false,
            passport: false,
            phone: false,
            privacyPolice: false
        }

        let isValid = true

        if (!formData.name) {
            newErrors.name = true
            isValid = false
        }

        if (!formData.lastName) {
            newErrors.lastName = true
            isValid = false
        }

        if (!formData.email) {
            newErrors.email = true
            isValid = false
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = true
            isValid = false
        }
        if (!formData.confirmEmail) {
            newErrors.confirmEmail = true
            isValid = false
        } else if (!/\S+@\S+\.\S+/.test(formData.confirmEmail)) {
            newErrors.confirmEmail = true
            isValid = false
        }
        if (!formData.passport) {
            newErrors.passport = true
            isValid = false
        }
        if (!formData.phone) {
            console.log("8")
            newErrors.phone = true
            isValid = false
        }


        if (!agreement1) {
            newErrors.privacyPolice = true
            isValid = false
        }

        setErrors(newErrors)
        setIsFormValid(isValid)
        return newErrors
    }



    const handleChangeAssistant = () => { }
    const handleNext = async () => {
        const errors = validateForm()
        const hasErrors = Object.values(errors).some(error => error);
        if (!hasErrors) {
            dispatch(saveMainPassenger(formData))
            navigation('/checkout/pago')
        }

        console.log('Formulario inválido')


        //  navigation('/checkout/pago')
    }

    useEffect(() => {
        if (tours.length > 0) {
            const extrPaxes = [];
            let maxValueSum = 0;
            let maxObject = null;
            tours.forEach((tour, index) => {
                const valueSum = tour.booking.reduce((sum, booking) => sum + booking.value, 0);
                if (valueSum > maxValueSum) {
                    maxValueSum = valueSum;
                    maxObject = tour;
                }
            });

            Array.from({ length: (maxValueSum - 1) }).forEach((_, index) => {
                extrPaxes.push({
                    id: `A${index + 1}`,
                    name: '',
                    lastName: '',
                    passport: '',
                    tours: tours.map((tour) => tour.slug)
                });
            });


            dispatch(setAssitants(extrPaxes));
        }
    }, [tours]);

    return (
        <Grid container spacing={3} sx={{ my: '30px' }}>
            <Grid item xs={12} md={8}>
                <Box
                    sx={{
                        backgroundColor: '#ffffff',
                        paddingBottom: '20px',
                        px: { xs: 0, md: '15px' }
                    }}>

                    <Box sx={{ pt: '25px' }}>
                        <Typography
                            variant="h5"
                            color="secondary"
                            sx={{
                                fontSize: '18px',
                                pb: '10px'
                            }}>
                            Datos del titular
                        </Typography>
                        <FormTitular
                            errors={errors}
                            formData={formData}
                            handleChange={handleChange}
                        />
                    </Box>
                </Box>
                {/* {assistants.length > 0 && <Box
                    sx={{
                        backgroundColor: '#ffffff',
                        py: '15px',
                        px: { xs: 0, md: '15px' },
                        mt: '20px',
                        borderRadius: '8px'
                    }}>
                    <Typography
                        variant="h5"
                        color="secondary"
                        sx={{
                            fontSize: '18px',
                            pb: '10px'
                        }}>
                        Añade los datos de los asistentes
                    </Typography>
                    <Typography
                        variant="body2"
                        component="p"
                        color="secondary"
                        sx={{ pb: '10px' }}>
                        Algunas atracciones exigen la documentación de todos los
                        asistentes. Si no puedes incluir esa documentación
                        ahora, podrás aportarla más adelante con tu operador
                        turístico.
                    </Typography>

                    {assistants.map((assistant, index) => (
                        <AccordionAssitant
                            key={index}
                            assitant={assistant}
                            tours={cartTours}
                            handleChange={handleChangeAssistant}
                            index={index}
                        />
                    ))}
                </Box>} */}

                <Box
                    sx={{
                        my: '20px'
                    }}>
                    <Agreements
                        handleChange={handleAgreements}
                        agreement1={agreement1}
                        agreement2={agreement2}
                        errors={errors}
                    />
                </Box>
                <Box
                    sx={{
                        display: { xs: 'none', md: 'flex' }
                    }}>
                    <Button
                        variant="contained"
                        onClick={handleNext}
                        disabled={false}
                        sx={{
                            width: '260px'
                        }}>
                        Continuar
                    </Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        sx={{
                            ml: '20px',
                            width: '160px'
                        }}>
                        Guardar carrito
                    </Button>
                </Box>
            </Grid>

            <Grid
                item
                xs={12}
                md={4}
                sx={{
                    display: {
                        xs: 'none',
                        md: 'block'
                    }
                }}>
                <Resumen cartTours={cartTours} />
            </Grid>
            <Grid
                item
                xs={12}
                md={4}
                sx={{
                    pl: { xs: '0!important' },
                    display: { xs: 'block', md: 'none' },
                    width: '100%'
                }}>
                <CardResumen
                    isFormValid={isFormValid}
                    handleNext={handleNext}
                />
            </Grid>
        </Grid>
    )
}

export default Step2
