import React from "react"
import {
    Box,
    CircularProgress,
    FormControl,
    Input as InlineInput,
    InputAdornment,
    OutlinedInput,
} from "@mui/material"
import PredictionsList from "../PredictionsList"
import { useClickOutsideDetector } from "../../hooks/useClickOutsideDetector"
import { ReactComponent as SearchIconFill } from "../../assets/icons/search-fill.svg"
import { getMockSuggestions, predictions } from "../../utils/data"
import { fetchPredictions, fetchAutocompleteDestinations } from "../../utils/API/guides"
import { getHrefFromGuide, getTourPath } from "../../utils/guias"
import stringSimilarity from "string-similarity";
import { useState, useEffect } from 'react';

const SearchInput = ({
    // placeHolder = "Busca un destino o tour",
    styles = {},
    source = "",
    SearchIcon = SearchIconFill,
    variant = "outlined"
}) => {
    const [value, setValue] = React.useState("")
    const [results, setResults] = React.useState({})
    const [openModal, setOpenModal] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false)
    const [placeHolder, setPlaceholder] = React.useState('Busca un destino o tour');
    const [autoComplete, setAutoComplete] = React.useState('');
    const wrapperRef = React.useRef(null);

    const inputVariants = {
        inline: InlineInput,
        outlined: OutlinedInput
    }
    const Input = inputVariants[variant];

    const levenshteinDistance = (a, b) => {
        const matrix = Array.from({ length: a.length + 1 }, (_, i) =>
            Array.from({ length: b.length + 1 }, (_, j) => (i === 0 ? j : j === 0 ? i : 0))
        );

        for (let i = 1; i <= a.length; i++) {
            for (let j = 1; j <= b.length; j++) {
                if (a[i - 1] === b[j - 1]) {
                    matrix[i][j] = matrix[i - 1][j - 1];
                } else {
                    matrix[i][j] = Math.min(
                        matrix[i - 1][j - 1], // Sustitución
                        matrix[i][j - 1],     // Inserción
                        matrix[i - 1][j]      // Eliminación
                    ) + 1;
                }
            }
        }

        return matrix[a.length][b.length];
    };

    const getAutocompleteSearch = async (value) => {
        setValue(value)
        if (!value) {
            setOpenModal(false);
        } else {
            setOpenModal(true)
            const baseURL = process.env.REACT_APP_BASE_URL;
            let result = await fetchAutocompleteDestinations(baseURL, value);
            if (result.length > 0) {
                setAutoComplete(result[0].name); // Parte faltante
            } else {
                setAutoComplete('');
            }
        }
    }

    const getSuggestionsSearch = async (value) => {
        setIsLoading(true);
        const baseURL = process.env.REACT_APP_BASE_URL;

        try {
            const predictions = await fetchPredictions(baseURL);

            const mapPredictions = predictions.map(item => ({
                name: item.title,
                path: getTourPath(item),
                type: item.type || undefined
            }));

            const levenshteinDistance = (a, b) => {
                const matrix = Array.from({ length: a.length + 1 }, (_, i) =>
                    Array.from({ length: b.length + 1 }, (_, j) => (i === 0 ? j : j === 0 ? i : 0))
                );

                for (let i = 1; i <= a.length; i++) {
                    for (let j = 1; j <= b.length; j++) {
                        if (a[i - 1] === b[j - 1]) {
                            matrix[i][j] = matrix[i - 1][j - 1];
                        } else {
                            matrix[i][j] = Math.min(
                                matrix[i - 1][j - 1],
                                matrix[i][j - 1],
                                matrix[i - 1][j]
                            ) + 1;
                        }
                    }
                }

                return matrix[a.length][b.length];
            };

            const suggestionsWithDistances = mapPredictions.map(suggestion => {
                const words = suggestion.name.toLowerCase().split(" ");
                const distances = words.map(word => levenshteinDistance(value.toLowerCase(), word));
                const minDistance = Math.min(...distances);
                return {
                    ...suggestion,
                    distance: minDistance
                };
            });

            const sortedSuggestions = suggestionsWithDistances
                .sort((a, b) => a.distance - b.distance)
                .slice(0, 3);

            setIsLoading(false);

            return {
                sugerencias: sortedSuggestions
            };
        } catch (error) {
            console.error("Error al obtener las sugerencias:", error);
            setIsLoading(false);

            return {
                sugerencias: []
            };
        }
    };

    const getGuidesPredictions = async (value) => {
        setIsLoading(true)
        const baseURL = process.env.REACT_APP_BASE_URL
        const predictions = await fetchPredictions(baseURL, value)
        const mapPredictions = predictions.map(item => ({
            name: item.title,
            path: getTourPath(item),
            type: (item.type) ? item.type : undefined
        }))

        const tourList = mapPredictions.filter(x => (x.type) === 'tour')
        const guideList = mapPredictions.filter(x => (x.type) === undefined)
        const citiesList = mapPredictions.filter(x => (x.type) === 'city')

        setIsLoading(false)
        return {
            'Destinos': citiesList,
            'Tours': tourList,
            'Guías de viaje': guideList,

        }
    }


    useClickOutsideDetector(wrapperRef, () => {
        setOpenModal(false);
    })

    const handleOnChange = async (evt) => {
        const { value } = evt.target;
        setValue(value)
        if (!value) {
            setOpenModal(false);
        } else {
            setOpenModal(true)
            const predictions = await getGuidesPredictions(value);
            const list = predictions['Destinos'].length > 0 || predictions['Guías de viaje'].length > 0 || predictions['Tours'].length > 0 ?
                predictions :
                await getSuggestionsSearch(value)
            setResults(list)
        }
    }


    useEffect(() => {
        getAutocompleteSearch(value);
    }, [value]);

    const handleKeyDown = (e) => {
        if (e.key === 'Tab' && autoComplete) {
            setValue(autoComplete);
            setAutoComplete('');
            e.preventDefault();
        }
    };

    const Loading = () => (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            py: 3,
        }}>
            <CircularProgress />
        </Box>
    )

    return (
        <Box
            sx={{
                display: "flex",
                flexWrap: "wrap",
                maxWidth: 650,
                width: "100%",
                ...styles
            }}>
            <FormControl ref={wrapperRef} sx={{ m: 1, width: "100%", position: "relative" }}>
                <Input
                    color="primary"
                    placeholder={placeHolder}
                    type={"text"}
                    value={value}
                    sx={{
                        borderRadius: variant === "outlined" ? 30 : 0,
                        paddingLeft: 1,
                        paddingRight: variant === "outlined" ? 2 : 1,
                        backgroundColor: variant === "outlined" ? "#ffffff" : 'transparent',
                        input: {
                            "&::placeholder": {
                                opacity: 0.8,
                                color: variant === "outlined" ? "#666B6E" : "#40C5E8"
                            },
                        },
                    }}
                    endAdornment={
                        <InputAdornment position="end">
                            <SearchIcon style={{
                                width: variant === "outlined" ? 36 : 30,
                                height: variant === "outlined" ? 36 : 30
                            }} />
                        </InputAdornment>
                    }
                    onChange={handleOnChange}
                    onKeyDown={handleKeyDown}
                />
                {/* Texto de sugerencia sobrepuesto */}
                <div
                    style={{
                        position: 'absolute',
                        top: '51%',
                        left: '22px',
                        transform: 'translateY(-50%)',
                        fontSize: '16px',
                        pointerEvents: 'none',
                        zIndex: 1,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        visibility: autoComplete ? 'visible' : 'hidden',
                    }}
                >
                    {value}
                    <span style={{ color: '#ccc' }}>{value !== '' ? autoComplete.substring(value.length) : ''}</span>
                </div>
                {openModal ? (
                    <Box
                        sx={{
                            backgroundColor: "#ffffff",
                            borderRadius: 3,
                            boxShadow: 10,
                            float: "bottom",
                            maxHeight: "20rem",
                            mt: variant === "outlined" ? "4rem" : "2.5rem",
                            position: "absolute",
                            overflowY: "scroll",
                            width: "100%",
                            zIndex: 10,
                            "&::-webkit-scrollbar": {
                                width: "10px",
                            },
                            "&::-webkit-scrollbar-track": {
                                backgroundColor: "#f1f1f1",
                                borderRadius: "10px",
                                marginBlock: "15px",
                            },
                            "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#888",
                                borderRadius: "10px",
                            },
                            "&::-webkit-scrollbar-thumb:hover": {
                                backgroundColor: "#555",
                            },
                        }}
                    >
                        {isLoading ? <Loading /> : <PredictionsList data={results} value={value} />}
                    </Box>
                ) : null}
            </FormControl>
        </Box>
    )
}

export default SearchInput
