import { useLayoutEffect, useRef, useState } from "react";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import ReviewCard from "../ReviewCard"
import { ReactComponent as Left } from "../../assets/icons/navigate-left.svg";
import { ReactComponent as Right } from "../../assets/icons/navigate-right.svg";
import bgImage from '../../assets/images/trekzy-pattern-bottom.svg'



const ReviewsCarousel = ({ data }) => {
    const { reviews, title1, title2, text, footer } = data;
    const [middleCard, setMiddleCard] = useState(Math.trunc(reviews.length / 2));
    const [widthContainer, setWidthContainer] = useState(0);
    const ref = useRef(null);
    let widthCard = 330, zoomCard = 100, countZoomCard = 0;

    const getPositionCard = (middleCard, initialcard, widthCard, positionCard) => {
        let countScale = 0;

        if (middleCard == initialcard) {
            return positionCard;
        }

        if (initialcard < middleCard) {
            for (let i = middleCard; i > initialcard; i--) {
                positionCard = (positionCard - ((widthCard - countScale)));
                countScale = + 33;
            }
        } else {
            for (let i = middleCard; i < initialcard; i++) {
                positionCard = (positionCard + ((widthCard - countScale)));
                countScale = + 33
            }
        }

        return positionCard;
    }

    const handleLeft = () => {
        setMiddleCard(middleCard == 1 ? 1 : middleCard - 1);
    }

    const handleRight = () => {
        setMiddleCard(middleCard == reviews.length ? reviews.length : middleCard + 1);
    }

    useLayoutEffect(() => {
        setWidthContainer(ref.current.offsetWidth);
    }, []);

    if (!data.reviews) return null

    return (
        <Box sx={{
            pt: "3rem",
            background: `url(${bgImage}), #223F7F`,
            backgroundSize: 'cover',
            textAlign: "center",
        }}
        >
            <Box sx={{ px: "15px" }}>
                <Typography
                    fontFamily={"Poppins"}
                    color='primary'
                    variant='h5'
                    component='h5'
                    sx={{
                        fontWeight: 600,
                        fontSize: "1.1rem"
                    }}>
                    {title1}
                </Typography>
                <Typography
                    fontFamily={"Poppins"}
                    color='primary.contrastText'
                    variant='h2'
                    component='h2'
                    sx={{
                        fontWeight: 600,
                        fontSize: { xs: "1.4rem", md: "1.7rem" },
                        pb: "10px"
                    }}>
                    {title2}
                </Typography>
                <Typography
                    color='primary.contrastText'
                    variant='body2'
                    component='p'
                    sx={{
                        fontSize: { xs: ".8rem", md: ".9rem" },
                    }}>
                    {text}
                </Typography>
            </Box>
            <Box ref={ref} sx={{
                overflow: "hidden",
                width: "100%",
                height: { xs: 310, md: 400 },
                position: "relative",
                display: "flex",
                alignItems: "center",
                mt: { xs: 0, md: "3rem" },
            }}>
                {reviews.map((review, index) => {
                    countZoomCard = Math.abs(((middleCard - (index + 1)) * 10));
                    let position = widthContainer <= widthCard ? 0 : (widthContainer / 2) - (widthCard / 2);
                    return (
                        <ReviewCard
                            key={review.title}
                            card={review}
                            positionCard={`${getPositionCard(middleCard, index + 1, widthCard, position)}px`}
                            zoomCard={`${zoomCard - countZoomCard}%`}
                        />
                    )
                })}
            </Box>
            <Box sx={{ textAlign: "center" }}>
                <IconButton
                    onClick={handleLeft}
                    aria-label='navigate-left'
                    sx={{ p: 0 }}
                >
                    <Left style={{ width: 44, height: 44 }} />
                </IconButton >
                <IconButton
                    onClick={handleRight}
                    aria-label='navigate-right'
                    sx={{ p: 0 }}
                >
                    <Right style={{ width: 44, height: 44 }} />
                </IconButton>
            </Box>
            <Grid
                container
                sx={{
                    bgcolor: "#40C5E8",
                    mx: "5%",
                    width: "auto",
                    px: "6%",
                    py: "20px",
                    borderRadius: "6px",
                    position: "relative",
                    top: "50px"

                }}
            >
                <Grid
                    item
                    xs={12}
                    md={9}
                    sx={{
                        textAlign: { sx: "center", md: "left" },
                        pb: "10px"
                    }}
                >
                    <Typography
                        fontFamily={"Poppins"}
                        color='primary.contrastText'
                        variant='h5'
                        component='h3'
                        sx={{
                            fontSize: { xs: "16px", md: "20px" },
                            fontWeight: 700
                        }
                        }>
                        {footer.title}
                    </Typography>
                    <Typography
                        color='primary.contrastText'
                        variant='body1'
                        component='p'
                        sx={{ fontSize: "13px" }}
                    >
                        {footer.text}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={3}
                >
                    <Button
                        fontFamily={"Poppins"}
                        variant='contained'
                        color='secondary'
                        href={footer.buttonUrl}
                        sx={{
                            borderRadius: "16px",
                            color: "#ffffff",
                            display: "grid",
                            fontWeight: 600,
                            height: "fit-content",
                            mt: ".8rem",
                            textTransform: "none",
                        }}
                    >
                        {footer.buttonLabel}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}

export default ReviewsCarousel
