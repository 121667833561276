import React from 'react'
import { Box, Link, Typography } from '@mui/material'
import MainLayout from '../../components/MainLayout'
import StaticPagesWrapper from '../../components/StaticPagesWrapper'
import ListItemHeader from '../../components/StaticPagesWrapper/ListItemHeader'

const Terms = () => (
    <MainLayout>
        <StaticPagesWrapper title='Términos y Condiciones'>
            <Box mb={8}>
                <ListItemHeader styles={{ padding: 0 }}>
                    Términos y Condiciones del Sitio Web y los servicios
                </ListItemHeader>
                <Typography mb={2}>
                    Bienvenido a TREKZY, este sitio web: <Link href='https://trekzy.com' target="_blank">https://trekzy.com</Link> o <Link href='https://toursprivados.com' target="_blank">https://toursprivados.com</Link> (
                    el <strong>“Sitio Web”</strong>) es propiedad, operado y/o administrado por The Wonder Of You S.L. (en lo sucesivo, “TREKZY”), una sociedad debidamente
                    constituida y válidamente existente de conformidad con las leyes del Reino de España
                    (<strong>“España”</strong>) (cada una de ellas individualmente, <strong>“TREKZY”</strong>, y según la jurisdicción en donde se
                    presten los servicios ofrecidos a través del Sitio Web) y se encuentra disponible para usuarios
                    de todo el mundo.
                    <br /><br />
                    TREKZY ofrece a través del Sitio Web diversos productos y servicios de hospitalidad, turismo
                    guiado, transportación, experiencias, así como entradas a monumentos, ruinas, templos,
                    museos, locaciones, entre otros y en distintos países (en lo sucesivo, los <strong>“Servicios”</strong> según sean
                    modificados, adicionados de tiempo en tiempo).
                    <br /><br />
                    El uso que usted haga (i) como visitante del Sitio Web; (ii) como persona física actuando por su
                    propio derecho o como persona moral/jurídica actuando de manera personal; o (iii) como persona
                    física o persona moral/jurídica actuando en nombre y por cuenta de terceras personas (<strong>“Usted”</strong>)
                    del Sitio Web y la contratación de cualquiera de los Servicios, está expresamente condicionado
                    a la aceptación de estos Términos y Condiciones (los <strong>“Términos y Condiciones”</strong>) manifestando
                    su consentimiento expreso con los mismos y quedando obligado a transmitir estos Términos y
                    Condiciones a cualquier persona que se beneficie o haga uso de los Servicios contratados a
                    través del Sitio Web y confirmados por la entidad aplicable de TREKZY a través de una
                    confirmación de orden de servicios (una <strong>“Reserva Confirmada”</strong>).
                </Typography>
                <ListItemHeader>
                    1. Declación de mayor de edad y capacidad
                </ListItemHeader>
                <Typography mb={2}>
                    Al acceder y utilizar este
                    Sitio Web, Usted declara bajo protesta de decir verdad y garantiza que tiene al menos 18 años
                    de edad (o la mayoría de edad legal en su jurisdicción de residencia) y que tiene la capacidad
                    de obligarse y contratar los servicios ofrecidos por TREKZY en el Sitio Web y que utilizará el
                    Sitio Web de acuerdo con estos Términos y Condiciones y que solo lo utilizará para hacer
                    reservaciones por su propio derecho o en nombre de terceras personas por las que
                    legítimamente puede actuar en su nombre. Este Sitio Web no está destinado a menores de edad,
                    y no recopilamos de manera intencionada información personal de menores. Si no cumple con
                    este requisito, debe abstenerse de utilizar el Sitio Web.
                    <br /><br />
                    En caso de que descubramos que hemos recopilado información personal de un menor sin
                    verificación del consentimiento paterno o del tutor legal, eliminaremos dicha información de
                    nuestros registros de manera inmediata. Si Usted es un padre o tutor y cree que su hijo nos ha
                    proporcionado información personal, por favor contáctenos para que podamos tomar las
                    medidas necesarias.
                    <br /><br />
                    Su nombre o en nombre de una persona por la que puede actuar en su nombre,
                    se hará responsable por los Términos de Uso que aplican a las reservaciones hechas en nombre
                    de esas personas, incluso cualquier regla o restricción,
                    toda la información que proporciona al sitio es verdadera, exacta, completa, actual.
                </Typography>
                <ListItemHeader>
                    2. Aceptación y consentimiento
                </ListItemHeader>
                <Typography mb={2}>
                    Previo al pago de su Reserva Confirmada le
                    pediremos que acepte estos Términos y Condiciones, dicha aceptación constituirá el
                    entendimiento total y un acuerdo legal y vinculante entre Usted y TREKZY respecto de los
                    Servicios que Usted ha contratado a través de una Reserva Confirmada.
                </Typography>
                <ListItemHeader>
                    3. Intermediario
                </ListItemHeader>
                <Typography mb={2}>
                    Usted reconoce que ciertos Servicios ofrecidos a través del Sitio
                    Web o experiencias asociados a su Reserva Confirmada son prestados u operados por personas
                    físicas y/o morales/jurídicas ajenas a TREKZY tales como servicios de transportación terrestre,
                    acuático o aéreo, hotelería, operadores de viajes en globo aerostático, hotelería, experiencias,
                    entre otros. Dichos servicios asociados o conexos a su Reserva Confirmada están sujetos a
                    términos y condiciones específicos de dichos terceros ajenos a TREKZY y podrían aplicar sus
                    propias políticas específicas de cargos adicionales por modificaciones, cancelaciones y
                    reembolsos, solicitudes especiales, pérdida de vuelos, pérdida o extravío de equipaje, seguros,
                    etcétera.
                    <br /><br />
                    Si bien haremos nuestros mejores esfuerzos para informarle de cualquier requisito o solicitud
                    especifica que dichos terceros requieran para gozar de los Servicios que TREKZY ha contratado
                    para Usted en virtud de una Reserva Confirmada asociada a los Servicios no estamos obligado
                    a ello y no seremos responsable frente a Usted por cualquier falta de documentación o requisitos
                    para gozar dichos Servicios prestados u operados por terceros ajenos a TREKZY,
                    específicamente y sin limitar, aquellos relacionados con transportación acuática, terrestre o
                    aérea y experiencias de viajes en globo aerostático, acceso a recintos, monumentos, museos y
                    parques.
                </Typography>
                <ListItemHeader>
                    4. Precios
                </ListItemHeader>
                <Typography mb={2}>
                    Las tarifas de los Servicios publicadas en el Sitio Web son válidas
                    solamente el día de su cotización y están sujetas a cambios sin previo aviso. Según el país
                    donde sean ofrecidos los Servicios, los precios pueden estar expresados en pesos, moneda de
                    curso legal de los Estados Unidos Mexicanos, en dólares, moneda de curso legal de los Estados
                    Unidos Americanos o en euros, moneda de curso legal en la Unión Europea.
                    <br /><br />
                    Las tarifas de los Servicios mostradas se garantizarán únicamente al momento de recibir el pago
                    correspondiente y cuando le sea emitida una Reserva Confirmada. Todas las reservaciones
                    están sujetas a disponibilidad. Una reservación se considera completada, cuando Usted cuenta
                    con un número de confirmación y el cargo correspondiente a su tarjeta de crédito o débito ha
                    sido aplicado.
                </Typography>
                <ListItemHeader>
                    5. Cupones y Promociones
                </ListItemHeader>
                <Typography mb={2}>
                    Los cupones, códigos de descuento o promociones
                    (en adelante, <strong>“Cupones”</strong>) son códigos promocionales que ofrecen descuentos sobre el precio
                    de uno o más Servicios del Sitio Web y los mismos tienen una validez limitada, que será indicada
                    en el propio Cupón. Una vez expirado el plazo de validez, el Cupón no podrá ser utilizado.<br /><br />
                    (a) Uso de los Cupones:
                    <ul>
                        <li>
                            Los Cupones pueden ser utilizados una sola vez y no son transferibles.
                        </li>
                        <li>
                            Los Cupones no son acumulables con otras ofertas, promociones o descuentos, a
                            menos que se indique expresamente lo contrario.
                        </li>
                        <li>
                            Para aplicar un Cupón, Usted previamente al pago de su orden de servicios deberá
                            ingresar el código del Cupón al momento de realizar la compra en el Sitio Web. La
                            falta de ingreso del Cupón previo a la compra no le facultará a solicitar cualquier
                            clase de bonificación o reembolso a su Reserva Confirmada y TREKZY no estará
                            obligado a aplicar el descuento de ningún Cupón vigente y legítimo que no haya sido
                            debidamente ingresado previo al pago de una orden de servicios en el Sitio Web.
                        </li>
                    </ul>
                    (b) Restricciones:
                    <ul>
                        <li>
                            Los Cupones no tienen valor en efectivo y no pueden ser canjeados por dinero en
                            efectivo.
                        </li>
                        <li>
                            Los Cupones no pueden ser utilizados para compras previas o futuras fuera del
                            período de validez especificado en el mismo.
                        </li>
                        <li>
                            TREKZY se reserva el derecho de cancelar cualquier Reserva Confirmada si se
                            detecta un uso fraudulento o inapropiado de los Cupones.
                        </li>
                    </ul>
                    (c) Modificación y Cancelación de Cupones:
                    <ul>
                        <li>
                            TREKZY se reserva el derecho de modificar o cancelar los Cupones en cualquier
                            momento sin previo aviso. En caso de modificación, los Cupones ya emitidos
                            mantendrán sus condiciones originales hasta su fecha de expiración.
                        </li>
                        <li>
                            En caso de cancelación del pedido por parte del usuario, el valor del Cupón no será
                            reembolsado ni podrá ser reutilizado.
                        </li>
                    </ul>
                    (d) Limitaciones de Responsabilidad**
                    <ul>
                        <li>
                            TREKZY no será responsables frente a Usted por Cupones perdidos o robados.
                        </li>
                        <li>
                            TREKZY no garantiza que los Cupones estarán disponibles en todo momento y se
                            reservamos el derecho de suspender temporal o permanentemente la emisión de
                            cupones.
                        </li>
                    </ul>
                </Typography>
                <ListItemHeader>
                    6. Contratación de los servicios
                </ListItemHeader>
                <Typography mb={2}>
                    Para contratar cualquiera de los Servicios
                    ofrecidos a través del Sitio Web, bastará con dar clic en la pestaña desplegable identificada
                    como “Tours” y seleccionar el país donde desea contratar los Servicios, una vez que Usted elija
                    el país le aparecerá una pantalla con las diferentes ciudades en las que TREKZY opera, cada
                    ciudad en específico ofrece diferentes tipos de Servicios y experiencias que serán desplegados
                    cuando de clic a cualquier ciudad. Detalles e información relevante sobre cada Servicio le será
                    mostrado así como la opción de reservar el Servicio de su agrado e interés. Una vez que haya
                    elegido el Servicio que desea contratar podrá reservarlo dando clic en la pestaña “Reservar
                    Ahora” y elegir la fecha y horario disponible que mejor le convenga a Usted, una vez elegida la
                    fecha y hora de los Servicios le indicaremos el precio por persona y le pediremos que nos indique
                    si Usted va solo o con uno o más acompañantes, incluyendo la especificación de si son adultos
                    (mayores de 12 años), si son niños (menores entre 3 y 11 años) o infantes (menores de 3 años),
                    una vez que haya realizado la elección le será desplegado un apartado de información adicional
                    que es obligatoria llenar que incluyen el nombre de Usted y sus acompañantes, el número de
                    identificación o pasaporte de Usted y sus acompañantes, la fecha de nacimiento de Usted y sus
                    acompañantes y un número telefónico de contacto así como los datos de pago.
                    <br /><br />
                    Una vez introducidos los datos Usted declara bajo protesta de decir verdad que dichos datos y
                    los de sus acompañantes son verdaderos, completos, correctos y precisos y Usted asume la
                    completa responsabilidad respecto de los datos proporcionados, liberando de toda
                    responsabilidad a TREKZY de cualquier negativa o de terceros de prestar total o parcialmente
                    uno o más Servicios contenidos en su Reserva Confirmada. Usted entiende que de proporcionar
                    datos falsos, incorrectos o imprecisos (incluyendo aquellos donde pretenda obtener un beneficio
                    de precios indicando edades diferentes a sus acompañantes) pudiera resultar en la no prestación
                    o imposibilidad de prestar los Servicios y sin la posibilidad de solicitar el reembolso. TREKZY a
                    su entera discreción podrá ofrecerle un cambio de fecha a su Reserva Confirmada pero no estará
                    obligado a ello y Usted entiende que dicha modificación podría aplicar penalizaciones o gastos
                    extras que Usted y sus acompañantes deberán cubrir.
                </Typography>
                <ListItemHeader>
                    7. Forma de pago
                </ListItemHeader>
                <Typography mb={2}>
                    El Sitio Web acepta tarjetas bancarias Visa, MasterCard y
                    American Express como forma de pago. Si prefiere pagar por medio de transferencia bancaria,
                    contacte al centro de atención telefónica de TREKZY en la sección de Contacto del Sitio Web
                    <br /><br />
                    El monto correspondiente a la reservación será cargado inmediatamente a su tarjeta de débito
                    o crédito y le haremos llegar la Reserva Confirmada al correo electrónico que nos señale una
                    vez procesada la transacción. Si Usted proporcionó un correo electrónico por error le pedimos
                    contactarnos de inmediato proporcionando los datos bancarios a efecto de hacerle llegar la
                    Reserva Confirmada a su correo electrónico.
                    <br /><br />
                    Tarifas mostradas en dólares americanos serán cobradas en dólares americanos y tarifas
                    mostradas en euros serán cobradas en euros para transacciones con tarjetas Visa y Mastercard.
                    Para transacciones con American Express la tarifa en dólares americanos será convertida a
                    pesos mexicanos al tipo de cambio vigente en la fecha de la transacción. Esta conversión puede
                    causar que el monto que aparecerá en el estado de cuenta de tu tarjeta de crédito muestre una
                    variación de hasta 5% por debajo o encima de la fluctuación internacional de tipos de cambio de
                    divisas. TREKZY no es responsable de esta fluctuación y al aceptar estos Términos y
                    Condiciones Usted reconoce haber sido informado sobre esta fluctuación en el tipo de cambio y
                    estar de acuerdo que el cargo con tarjetas American Express se realice en pesos mexicanos o
                    en euros.
                    <br /><br />
                    Las propinas no están incluidas en el precio de los Servicios a menos que así se indique en la
                    Reserva Confirmada. Cualquier servicio opcional durante la prestación de los Servicios que
                    genere un gasto adicional extra y que no conste como incluido en el monto total y que tampoco
                    se incluya de manera gratuita por parte de los prestadores finales, deberá ser pagado por Usted.
                </Typography>
                <ListItemHeader>
                    8. Modficaciones al sitio web, servicios, destinos y precios
                </ListItemHeader>
                <Typography mb={2}>
                    Los precios de los productos y servicios publicados en el Sitio Web están sujetos a cambios, sin
                    notificación alguna. Nos reservamos el derecho en cualquier momento de modificar o
                    descontinuar uno o más servicios (o cualquier parte del contenido del Sitio) en cualquier
                    momento. No nos hacemos responsables ante Usted o cualquier tercero por las modificaciones,
                    cambio de precio, suspensión o interrupción del Servicio.
                </Typography>
                <ListItemHeader>
                    9. Exclusión de responsabilidad sobre imágenes ilustrativas
                </ListItemHeader>
                <Typography mb={2}>
                    Las imágenes utilizadas en el Sitio Web son meramente ilustrativas y tienen el propósito de
                    proporcionar una representación visual general de los destinos turísticos. Aunque hacemos
                    esfuerzos razonables para garantizar que las imágenes sean precisas y actualizadas, pueden
                    no reflejar fielmente las características actuales de los destinos, incluyendo cambios
                    estacionales, remodelaciones o desarrollos recientes.
                </Typography>
                <ListItemHeader>
                    10. Documentación y liberación de responsabilidad por negativa de accesos
                </ListItemHeader>
                <Typography mb={2}>
                    TREKZY no se hace responsable frente a Usted y/o sus acompañantes por la
                    negativa de acceso a cualquier país, destino turístico o por el uso de los Servicios contratados
                    debido a la falta de documentación requerida, como pasaportes, visas, identificaciones oficiales
                    u otros documentos necesarios. Es responsabilidad exclusiva de Usted y sus acompañantes
                    asegurarse de que previo al inicio de los Servicios posean toda la documentación y cumplan con
                    todos los requisitos pertinentes y válidos antes de hacer uso de los Servicios. TREKZY hará sus
                    mejores esfuerzos en notificarle previamente si su Servicios contratado requiere la exhibición de
                    cierta documentación en específico y no será responsable frente a Usted y/o sus acompañantes
                    por la negativa de accesos a destinos o locaciones de terceros, le recomendamos ampliamente
                    revisar previamente al inicio de la prestación de los Servicios los requisitos de documentación y
                    demás específicos (incluyendo sin limitar, altura, edad o condiciones físicas, vestimenta, entre
                    otras) en cada jurisdicción, destinos o lugares, incluyendo, sin limitar, países o regiones, museos,
                    monumentos, iglesias, catedrales, parques, parques ecológicos, parques recreativos, bodegas,
                    fabricas, restaurantes, plazas, embarcaciones, etc. El no cumplir con los requisitos de
                    documentación puede resultar en la negativa de entrada a dichos lugares así como la
                    imposibilidad de prestar de manera adecuada los Servicios por parte de TREKZY. TREKZY no
                    se hace responsable frente a Usted y sus acompañantes por ningún gasto, inconveniente o
                    pérdida, acción y omisión que pueda resultar de la negativa de accesos debido a la falta de
                    documentación adecuada a Usted y/o cualquiera de sus acompañantes.
                </Typography>
                <ListItemHeader>
                    11. Declaración de consentimiento y asunción de riesgos
                </ListItemHeader>
                <Typography mb={2}>
                    Al contratar uno o más Servicios a través del Sitio Web, Usted declara bajo protesta de decir verdad
                    que Usted o Usted y sus acompañantes cuentan con un seguro personal y/o seguro médico
                    adecuado y suficiente que cubra cualquier daño, accidente o lesiones que le ocurran a Usted y/o
                    sus acompañantes en su persona o en sus bienes durante la ejecución de los Servicios.
                    <br /><br />
                    Usted y sus acompañantes entienden que los Servicios implican riesgos inherentes que podrían
                    resultar en lesiones, enfermedades, virus o daños, incluyendo sin limitar, accidentes de tránsito,
                    volcaduras, caídas, picaduras (de animales terrestres, marinos o aéreos), robo, asalto,
                    accidentes de navegación, ahogamiento, condiciones climáticas adversas y otros riesgos
                    conocidos y desconocidos por lo que al contratar los Servicios Usted y sus acompañantes liberan
                    a TREKZY de toda responsabilidad, reclamación, demanda, acción o daño de cualquier tipo o
                    naturaleza que pudiera surgirle a Usted y/o a sus acompañantes relacionada con su participación
                    en los Servicios por cualquier riesgo inherente a los mismos que pudieran resultar en lesiones,
                    enfermedades o daños, incluyendo sin limitar, accidentes de tránsito, volcaduras, caídas,
                    picaduras (de animales terrestres, marinos o aéreos), robo, asalto, accidentes de navegación,
                    ahogamiento, condiciones climáticas adversas y otros riesgos conocidos y desconocidos y que
                    Usted y/o sus acompañantes asumirán la responsabilidad financiera de cualquier costo, daño o
                    lesiones que pudieran experimentar durante su participación en los Servicios contratados.
                </Typography>
                <ListItemHeader>
                    12. Vacunación y alergias
                </ListItemHeader>
                <Typography mb={2}>
                    Sin perjuicio de los requisitos de las
                    autoridades, TREKZY no le requerirá ir provisto de vacunas previas para utilizar los Servicios.
                    Entendemos que la salud y la seguridad de Usted y sus acompañantes son de suma importancia.
                    Sin embargo, en este momento, no exigimos ni verificamos el estado de vacunación de nuestros
                    clientes antes de que utilicen nuestros Servicios. Le recomendamos ampliamente que Usted y
                    sus acompañantes sigan las pautas de salud pública y aquellas recomendadas por su médico
                    de cabecera que consideren pertinentes previo al uso de los Servicios, pero la decisión de
                    vacunarse o no queda a criterio personal de cada individuo.
                    <br /><br />
                    Al aceptar y utilizar nuestros Servicios, Usted y sus acompañantes entienden y aceptan que
                    TREKZY no asumirá ninguna responsabilidad en relación con la falta de vacunaciones
                    especificas y/o por el surgimiento de alergias que le ocurran Usted y/o a cualquiera de sus
                    acompañantes durante los Servicios ni por cualquier consecuencia derivada de su decisión de
                    no vacunarse previo al uso de los Servicios. Usted y sus acompañantes entienden que debido a
                    los riesgos inherentes de los Servicios, los mismos pudieran resultar en contacto directo con la
                    naturaleza y ciertos elementos u organismos venenosos o alérgicos para Usted y/o sus
                    acompañantes, incluyendo picaduras o mordeduras de animales salvajes o no, por lo que Usted
                    y sus acompañantes asumen toda la responsabilidad por su propia salud y bienestar durante el
                    uso de nuestros Servicios. Le recomendamos ampliamente contactar a un médico local
                    especializado previo al uso de los Servicios para conocer los riesgos a los que Usted y sus
                    acompañantes podrán estar expuestos en el uso de los Servicios y las vacunas o protecciones
                    que pudieran requerir para evitar cualquier alergia o exposición a elementos, animales u
                    organismos venenosos. Si Usted o alguno de sus acompañantes es alérgico a algún animal,
                    sustancia u organismo de la naturaleza especifico en el destino donde pretenda contratar los
                    Servicios le recomendamos no realizarlo.
                </Typography>
                <ListItemHeader>
                    13. Capacidades físicas
                </ListItemHeader>
                <Typography mb={2}>
                    Usted declara bajo protesta de decir verdad
                    que Usted y sus acompañantes tienen la capacidad física adecuada y que están en buen estado
                    de salud para participar en cualquier actividad de los Servicios contratados y que no tienen
                    condiciones médicas o limitaciones físicas que pudieran verse agravadas por la participación de
                    Usted y/o sus acompañantes en actividades físicas, acuáticas o terrestres. Si Usted o alguno
                    sus acompañantes tienen alguna limitación conocida o desconocida para realizar cualquier
                    actividad de los Servicios le sugerimos no contratar el Servicios y en caso de contratarlo y hacer
                    uso de los Servicios con dichas limitaciones conocidas o no, Usted y sus acompañantes liberan
                    de toda responsabilidad a TREKZY de toda responsabilidad, reclamación, demanda, acción,
                    daño o lesiones de cualquier tipo o naturaleza que pudiera surgirle a Usted y/o a sus
                    acompañantes relacionada con su participación en los Servicios y que Usted y/o sus
                    acompañantes asumirán la responsabilidad financiera de cualquier costo, daño o lesiones que
                    pudieran experimentar durante su participación en los Servicios contratados.
                </Typography>
                <ListItemHeader>
                    14. Uso de Equipamiento
                </ListItemHeader>
                <Typography mb={2}>
                    Usted y sus acompañantes se obligan a utilizar
                    todo el equipo de seguridad proporcionado y a seguir estrictamente las instrucciones de los guías
                    y personal de TREKZY durante las actividades de los SERVICIOS contratados
                </Typography>
                <ListItemHeader>
                    15. Consentimiento para atención medica
                </ListItemHeader>
                <Typography mb={2}>
                    En caso de que le ocurra
                    a Usted o alguno de sus acompañantes cualquier clase de emergencia, alergia, padecimiento,
                    picadura, o mordedura o accidente durante los Servicios, Usted y sus acompañantes autorizan
                    a TREKZY a organizar la atención medica necesaria para atender la misma y aceptan
                    expresamente ser responsables de los costos asociados con dicha atención, incluyendo sin
                    limitar, la solicitud de servicio médico y/o la contratación de ambulancias (terrestres, aéreas o
                    acuáticas). En la medida en que las circunstancias lo permitan y que sea posible para TREKZY
                    la transportación de Usted y/o cualquiera de sus acompañantes al hospital o centro médico más
                    cercano será realizado sin costo alguno.
                </Typography>
                <ListItemHeader>
                    16. Reclamaciones
                </ListItemHeader>
                <Typography mb={2}>
                    Una vez disfrutados los Servicios bajo una Reserva
                    Confirmada, toda queja, inconformidad y/o aclaración deberá realizarse por escrito, dirigido a
                    TREKZY.<br /><br />
                    Usted y sus acompañantes entienden y aceptan que los Servicios contratados bajo una Reserva
                    Confirmada son contratados bajo un esquema de prestación de servicios integral que no da lugar
                    a divisiones parciales de los mismos y entiende que no se efectuará devolución alguna por
                    Servicios parcialmente no utilizados voluntariamente por Usted y/o sus acompañantes contenidos
                    en una Reserva Confirmada.<br /><br />
                    En caso de que no se preste alguno de los Servicios operados por personas físicas o
                    morales/jurídicas terceros y ajenos a TREKZY bajo una Reserva Confirmada por causa
                    imputable o negativa no justificada de dicho terceros y Usted deba buscarlos o contratarlos por
                    su cuenta y a su costa, le pedimos que exija los comprobantes de pago necesarios para que
                    TREKZY pueda realizar la gestión de reembolso en su totalidad, de no contar con un
                    comprobante, la procedencia del reembolso será previo análisis del caso concreto por parte de
                    TREKZY y se llevará a cabo acorde al método de pago inicial y a las políticas del tercero. Por
                    favor tome en cuenta que esto no será aplicable cuando la negativa de dicho tercero a prestar
                    los Servicios operados por terceros sea consecuencia de la falta total o parcial de Usted y/o
                    cualquiera de sus acompañantes a cualquiera de las disposiciones de estos Términos y
                    Condiciones, a los términos y condiciones y políticas de dichos terceros, por declaraciones falsas
                    realizadas por Usted al momento de realizar la reserva, por pérdida de vuelos, transportes o
                    experiencias, por no ir vestidos de manera adecuada, por alterar el orden público o cualquier
                    otra causa.
                </Typography>
                <ListItemHeader>
                    17. Cancelaciones, modificaciones y especificaciones
                </ListItemHeader>
                <Typography mb={2}>
                    Para modificaciones, cancelaciones o solicitudes adicionales a su Reserva Confirmada por favor
                    refiérase a la Política General de Cancelación y Reembolso de Reservaciones publicada en el
                    Sitio Web.
                </Typography>
                <ListItemHeader>
                    18. No relación laboral
                </ListItemHeader>
                <Typography mb={2}>
                    Si Usted es una persona física o moral/jurídica
                    que ha contratado uno o más Servicios a través del Sitio Web a nombre y por cuenta de terceros
                    beneficiarios de los Servicios o si es un prestador de servicios que mantiene una relación
                    contractual con TREKZY para prestar cualquiera de los Servicios, Usted acuerda expresamente
                    que dicha relación no crea ninguna clase asociación, sociedad o relación laboral entre Usted y
                    TREKZY, ni con sus empleados, factores o dependientes, por lo que cada una de las partes será
                    la única responsable de sus obligaciones derivadas de las disposiciones legales y demás
                    ordenamientos en materia del trabajo y seguridad social. Usted y TREKZY son partes
                    contratantes independientes y ninguna disposición incluida en la Confirmación hará que
                    cualquiera de las partes sea agente o representante legal de la otra para ningún fin, sea cual fuere,
                    ni otorgará a ninguna de las partes autoridad alguna para asumir o crear ninguna obligación en
                    representación o en nombre de la otra.
                </Typography>
                <ListItemHeader>
                    19. Licencia de uso y propiedad industrial e intelectual
                </ListItemHeader>
                <Typography mb={2}>
                    TREKZY le otorga a Usted una licencia de uso mundial, limitada, no exclusiva, intransferible y
                    revocable para acceder y hacer uso del Sitio Web con el único y exclusivo fin de contratar cualquiera
                    de los SERVICIOS ofrecidos a través del mismo por TREKZY (la <strong>“Licencia de Uso”</strong>).<br /><br />
                    Restricciones a la Licencia de Uso. Al utilizar el sitio Web Usted no podrá:
                    <ul>
                        <li>
                            Modificar, adaptar, traducir, o realizar trabajos derivados del Sitio Web.
                        </li>
                        <li>
                            Reproducir, duplicar, copiar, vender, revender, o explotar de cualquier otra manera con
                            fines comerciales cualquier parte del Sitio Web.
                        </li>
                        <li>
                            Utilizar técnicas de enmarcado (framing) para adjuntar cualquier marca registrada,
                            logotipo, o cualquier otra información propiedad de TREKZY (incluyendo imágenes,
                            texto, diseño de página, o forma) sin el consentimiento expreso por escrito de TREKZY.
                        </li>
                        <li>
                            Recopilar información del Sitio Web mediante el uso de cualquier medio automatizado
                            (incluyendo scripts, bots, rastreadores o scrapers).
                        </li>
                    </ul>
                    El Sitio Web y todo su contenido, incluyendo pero no limitado a texto, gráficos, imágenes, logotipos,
                    íconos, y software, son propiedad exclusiva de TREKZY o sus licenciantes y están protegidos por
                    las leyes de propiedad intelectual aplicables. Nada en este acuerdo se interpretará como una
                    transferencia de derechos de propiedad intelectual a Usted.<br /><br />
                    TREKZY se reserva el derecho de rescindir esta licencia en cualquier momento y restringirle el
                    acceso o la prestación de los SERVICIOS sin responsabilidad alguna y por cualquier motivo, sin
                    previo aviso. En caso de terminación, Usted deberá cesar inmediatamente el uso del Sitio Web.<br /><br />
                    TREKZY no será responsable frente a Usted por ningún daño indirecto, incidental, especial,
                    consecuente o punitivo que surja del uso del Sitio Web.<br /><br />
                    Usted por ningún motivo podrá hacer uso o explotación en su beneficio, de las marcas, avisos
                    comerciales, o cualquier otro derecho de propiedad intelectual que pertenezca a TREKZY y/o
                    que estén contenidos en el Sitio Web, por lo que será responsable de las sanciones civiles,
                    administrativas o penales que resulten por la violación a los derechos de propiedad industrial e
                    intelectual referidos.
                </Typography>
                <ListItemHeader>
                    20. Protección de datos personales
                </ListItemHeader>
                <Typography mb={2}>
                    Para cuestiones relacionadas sobre
                    como TREKZY maneja y protege sus datos personales por favor refiérase al Aviso de Privacidad
                    publicado en el Sitio Web si Usted se encuentra en México y a la Política de Privacidad si Usted
                    se encuentra en la Unión Europea o en cualquier país diferente a México.
                </Typography>
                <ListItemHeader>
                    21. Caso fortuito y fuerza mayor
                </ListItemHeader>
                <Typography mb={2}>
                    Usted entiende y acuerda
                    expresamente que TREKZY no será responsable frente a Usted y sus acompañantes de
                    cualquier imposibilidad de prestar los SERVICIOS total o parcialmente cuando dicho
                    impedimento sean resultado de un caso fortuito o fuerza mayor como puede ser huracán, puerto
                    cerrado, trafico excesivo, bloqueos, decisiones administrativas de lugares y museos,
                    inundaciones, caminos enlodados o bloqueados, tormentas tropicales, epidemias, pandemias o
                    cualquier otro fenómeno natural o causado por el hombre y ajenos a TREKZY; para estos casos
                    solamente se aplicará la política de cambio de fecha de su Reserva Confirmada y caso por caso
                    y a entera discreción de TREKZY podrá aplicar el reembolso total o parcial de la Reserva
                    Confirmada.
                </Typography>
                <ListItemHeader>
                    22. Seguros
                </ListItemHeader>
                <Typography mb={2}>
                    REKZY mantiene contratada una póliza general de seguro
                    de responsabilidad civil, por daños a terceros y seguro de viajero, la cual ampara a Usted y sus
                    acompañantes durante los SERVICIOS. La responsabilidad de TREKZY queda cubierta con esta
                    póliza que se exhibe a la firma del presente contrato. Su cobertura aplica únicamente durante las
                    travesías en los autobuses. Dicha póliza se renovará anualmente y TREKZY deberá presentarla
                    a EL CLIENTE.
                </Typography>
                <ListItemHeader>
                    23. Liberación de responsabilidad por opiniones personales en redes sociales y otros sitios de internet
                </ListItemHeader>
                <Typography mb={2}>
                    Las opiniones,
                    comentarios y valoraciones sobre nuestros Servicios mostrados en el Sitio Web o que sean
                    expresados por los usuarios en redes sociales o cualquier otra plataforma en línea son
                    exclusivamente opiniones personales de dichos usuarios y nosotros no asumimos ninguna
                    responsabilidad u obligación frente a Usted y sus acompañantes al respecto. Estas opiniones
                    son meramente de carácter informativo y no reflejan necesariamente nuestra posición, política
                    o punto de vista oficial. No asumimos ninguna responsabilidad por cualquier falsa expectativa
                    en los Servicios derivado de una opinión de otros usuarios de los Servicios. Instamos a los
                    usuarios de nuestros Servicios a que realicen comentarios respetuosos y veraces, evitando
                    difamaciones, calumnias o cualquier otro contenido que pueda ser considerado ofensivo, ilegal,
                    impreciso o falso.
                </Typography>
                <ListItemHeader>
                    24. Legislación  y jurisdicción aplicable
                </ListItemHeader>
                <Typography mb={2}>
                    Estos Términos y Condiciones se regirán e interpretarán de acuerdo con las leyes del Reino de España. Usted
                    acuerda que cualquier controversia o reclamación derivada de, o relacionada con, estos
                    Términos y Condiciones, su cumplimiento, interpretación, ejecución o validez, será sometida a
                    la jurisdicción exclusiva de los tribunales competentes de la ciudad de Madrid, España,
                    renunciando expresamente a cualquier otro fuero que pudiera corresponderles.<br /><br />
                    El consentimiento otorgado por Usted al momento de contratar cualquiera de los SERVICIOS a
                    través del Sitio Web representa la aceptación total y el consentimiento expreso de Usted y sus
                    acompañantes a estos Términos y Condiciones. En caso de que no sea posible la firma autógrafa
                    en el documento por virtud de la utilización de medios electrónicos y/o tecnológicos, el
                    consentimiento pleno y total aceptación de Usted y sus acompañantes se entenderá
                    indudablemente manifestado desde el momento que realice el pago de su reservación y TREKZY
                    le emita una Reserva Confirmada.
                </Typography>
            </Box>
        </StaticPagesWrapper>
    </MainLayout>
)

export default Terms
